import React, { useState } from "react"
import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"
import useScript from "../service/useScript"

const ServiceDetailAllergy = () => {
    const metaTags = []
    useScript("/assets/js/allergy-detail.js")
    return (
        <>
            <Layout>
                <Meta
                    files={{
                        js: [],
                        css: [
                            "/assets/css/services-detail.css",
                            "/assets/css/accessibility.css",
                        ],
                    }}
                    tags={metaTags}
                />
                <main className="innerpage">
                    <section class="speciality_section section-py">
                        <div class="container mt-4">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="consultant_col">
                                        <div class="head">
                                            <a href="javascript:void(0)" class="back_arrow"></a>
                                            <h2>Allergy Consultants</h2>
                                        </div>
                                        <div class="doctors_list">
                                            <div class="doctor_card">
                                                <div class="doctor_img">
                                                    <img src="assets/images/dr_Asha.jpg" alt="" />
                                                    <a href="#">View Profile</a>
                                                </div>
                                                <div class="doctor_detail">
                                                    <h3 class="name">Dr. Asha V. Pherwani</h3>
                                                    <p class="desig">
                                                        Consultant Pediatrician and Child and Adult Allergy
                                                        & Asthma Specialist
                                                    </p>
                                                    <hr />
                                                    <p class="exp">14 years of experience overall</p>
                                                    <p class="availability">Available Today</p>
                                                    <p class="btn_wrap">
                                                        <a href="#" class="btn btn-primary">
                                                            Book Appointment
                                                        </a>
                                                    </p>
                                                    {/* <p class="off_text">Rs100 OFF on Online Booking</p> */}
                                                </div>
                                            </div>
                                            <div class="doctor_card">
                                                <div class="doctor_img">
                                                    <img src="assets/images/dr_Pravin.jpg" alt="" />
                                                    <a href="#">View Profile</a>
                                                </div>
                                                <div class="doctor_detail">
                                                    <h3 class="name">Dr Pravin Gore</h3>
                                                    <p class="desig">General & Laproscopic Surgery</p>
                                                    <hr />
                                                    <p class="exp">10 years of experience overall</p>
                                                    <p class="availability">Available Today</p>
                                                    <p class="btn_wrap">
                                                        <a href="#" class="btn btn-primary">
                                                            Book Appointment
                                                        </a>
                                                    </p>
                                                    {/* <p class="off_text">Rs100 OFF on Online Booking</p> */}
                                                </div>
                                            </div>
                                            <div class="doctor_card">
                                                <div class="doctor_img">
                                                    <img src="assets/images/dr_dummy.jpg" alt="" />
                                                    <a href="#">View Profile</a>
                                                </div>
                                                <div class="doctor_detail">
                                                    <h3 class="name">Dr. A.M. Bhagwati</h3>
                                                    <p class="desig">
                                                        renowned in the field of General Medicine
                                                    </p>
                                                    <hr />
                                                    <p class="exp">16 years of experience overall</p>
                                                    <p class="availability">Available Today</p>
                                                    <p class="btn_wrap">
                                                        <a href="#" class="btn btn-primary">
                                                            Book Appointment
                                                        </a>
                                                    </p>
                                                    {/* <p class="off_text">Rs100 OFF on Online Booking</p> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <div class="service_detail_banner">
                                        <div class="img_section">
                                            <img src="/assets/images/icons/heart_care_white.svg" alt="" />
                                        </div>
                                        <div class="text_section">
                                            <h2>Heart (Cardiac) Care</h2>
                                        </div>
                                    </div>
                                    <div class="accordion_container">
                                        <div
                                            id="accordion"
                                            role="tablist"
                                            class="common_accordion specialty_accordion"
                                        >
                                            <div class="card">
                                                <div
                                                    class="card-header"
                                                    role="tab"
                                                    id="headingOverview"
                                                >
                                                    <h5 class="mb-0">
                                                        <a
                                                            class=""
                                                            data-toggle="collapse"
                                                            href="#sp_Overview"
                                                            aria-expanded="false"
                                                            aria-controls="sp_Overview"
                                                        >
                                                            Overview
                                                        </a>
                                                    </h5>
                                                </div>
                                                <div
                                                    id="sp_Overview"
                                                    class="collapse show"
                                                    role="tabpanel"
                                                    aria-labelledby="headingOverview"
                                                    data-parent="#accordion"
                                                >
                                                    <div class="card-body">
                                                        <p>
                                                            Allergies are common in all age groups. It is a
                                                            response of one’s own body immune system to agents
                                                            like dust, metal, fibre, chemical. These agents
                                                            also known as allergen are not harmful, occur
                                                            freely in environment and do not bother most
                                                            people they come in contact with. People who have
                                                            allergies often are sensitive to more than one
                                                            allergen. When an allergen comes in contact with
                                                            an individual with allergy, it triggers an
                                                            allergic or hypersensitive reaction. Heredity and
                                                            environmental exposures contribute to
                                                            susceptibility of an individual to allergies. A
                                                            comprehensive boutique of care and treatment is
                                                            provided by Allergist at Hinduja Hospital after
                                                            analyzing all aspects of your lifestyle.
                                                        </p>
                                                        <h4>What is allergy? </h4>
                                                        <p>
                                                            The human immune system is programmed to combat
                                                            any foreign substance (allergen, infectious
                                                            agents) entering our body. In this process of
                                                            dealing with allergens, the immune system produces
                                                            a number of antibodies and activates blood called
                                                            eosinophils. Together they attack the allergen
                                                            with chemical substances. Sometimes the same
                                                            chemicals can result in symptoms of allergy.
                                                        </p>
                                                        <p>
                                                            Symptoms of allergies may be obvious such as hay
                                                            fever or not so obvious such as breathlessness
                                                            after taking certain medicines. An allergic
                                                            reaction can occur anywhere in the body (e.g.
                                                            sneezing and stuffy nose, itching rash of
                                                            urticaria and asthma). However other symptoms like
                                                            severe urticaria, migraine and even life-
                                                            threatening symptoms (reactions to drugs, foods
                                                            and cosmetics) can occur.
                                                        </p>
                                                        <ul>
                                                            <li> Specific allergic disease </li>
                                                            <li> Allergic rhinitis / sinusitis </li>
                                                            <li> Allergic conjunctivitis </li>
                                                            <li> Exercise-induced asthma </li>
                                                            <li> Chronic cough </li>
                                                            <li> Vocal cord dysfunction </li>
                                                            <li> Eczema, Atopic dermatitis </li>
                                                            <li> Urticaria, Angioedema </li>
                                                            <li> Contact Dermatitis </li>
                                                        </ul>
                                                        <p>
                                                            It is important to detect what is causing the
                                                            allergy in an individual. After detecting the
                                                            allergens , the best way is to avoid them (drug
                                                            allergy), use control measures (house dust mites),
                                                            create tolerance to them (food allergies), skin
                                                            protection (urticaria) or treat them with oral
                                                            vaccines (allergic colds or asthma We detect
                                                            allergies by the skin Prick tests which in the
                                                            hand of an allergy specialist are 90-100%
                                                            sensitive.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div
                                                    class="card-header"
                                                    role="tab"
                                                    id="headingSpTraining"
                                                >
                                                    <h5 class="mb-0">
                                                        <a
                                                            class="collapsed"
                                                            data-toggle="collapse"
                                                            href="#SpTraining"
                                                            aria-expanded="false"
                                                            aria-controls="SpTraining"
                                                        >
                                                            Special Training
                                                        </a>
                                                    </h5>
                                                </div>
                                                <div
                                                    id="SpTraining"
                                                    class="collapse"
                                                    role="tabpanel"
                                                    aria-labelledby="headingSpTraining"
                                                    data-parent="#accordion"
                                                >
                                                    <div class="card-body">
                                                        Anim pariatur cliche reprehenderit, enim eiusmod
                                                        high life accusamus terry richardson ad squid. 3
                                                        wolf moon officia aute, non cupidatat skateboard
                                                        dolor brunch. Food truck quinoa nesciunt laborum
                                                        eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put
                                                        a bird on it squid single-origin coffee nulla
                                                        assumenda shoreditch et.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div
                                                    class="card-header"
                                                    role="tab"
                                                    id="headingProTraining"
                                                >
                                                    <h5 class="mb-0">
                                                        <a
                                                            class="collapsed"
                                                            data-toggle="collapse"
                                                            href="#proTraining"
                                                            aria-expanded="false"
                                                            aria-controls="proTraining"
                                                        >
                                                            Professional Experience and Training
                                                        </a>
                                                    </h5>
                                                </div>
                                                <div
                                                    id="proTraining"
                                                    class="collapse"
                                                    role="tabpanel"
                                                    aria-labelledby="headingProTraining"
                                                    data-parent="#accordion"
                                                >
                                                    <div class="card-body">
                                                        Anim pariatur cliche reprehenderit, enim eiusmod
                                                        high life accusamus terry richardson ad squid. 3
                                                        wolf moon officia aute, non cupidatat skateboard
                                                        dolor brunch. Food truck quinoa nesciunt laborum
                                                        eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put
                                                        a bird on it squid single-origin coffee nulla
                                                        assumenda shoreditch et.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="gallery_container">
                                        <h2 class="section-heading">Image Gallery</h2>
                                        {/* <!-- MAIN SLIDES --> */}
                                        <div class="slider">
                                            <div class="main_slide">
                                                <figure>
                                                    {" "}
                                                    <img
                                                        src="/assets/images/tour_guide_01.jpg"
                                                        alt="One"
                                                    />
                                                </figure>
                                            </div>
                                            <div class="main_slide">
                                                <figure>
                                                    {" "}
                                                    <img
                                                        src="/assets/images/tour_guide_02.jpg"
                                                        alt="One"
                                                    />
                                                </figure>
                                            </div>
                                            <div class="main_slide">
                                                <figure>
                                                    {" "}
                                                    <img
                                                        src="/assets/images/tour_guide_03.jpg"
                                                        alt="One"
                                                    />
                                                </figure>
                                            </div>
                                            <div class="main_slide">
                                                <figure>
                                                    {" "}
                                                    <img
                                                        src="/assets/images/tour_guide_01.jpg"
                                                        alt="One"
                                                    />
                                                </figure>
                                            </div>
                                            <div class="main_slide">
                                                <figure>
                                                    {" "}
                                                    <img
                                                        src="/assets/images/tour_guide_02.jpg"
                                                        alt="One"
                                                    />
                                                </figure>
                                            </div>
                                        </div>
                                        {/* <!-- THUMBNAILS --> */}
                                        <div class="slider-nav-thumbnails">
                                            <div class="thumb_slide">
                                                <figure>
                                                    {" "}
                                                    <img
                                                        src="/assets/images/tour_guide_01.jpg"
                                                        alt="One"
                                                    />
                                                </figure>
                                            </div>
                                            <div class="thumb_slide">
                                                <figure>
                                                    {" "}
                                                    <img
                                                        src="/assets/images/tour_guide_02.jpg"
                                                        alt="One"
                                                    />
                                                </figure>
                                            </div>
                                            <div class="thumb_slide">
                                                <figure>
                                                    {" "}
                                                    <img
                                                        src="/assets/images/tour_guide_03.jpg"
                                                        alt="One"
                                                    />
                                                </figure>
                                            </div>
                                            <div class="thumb_slide">
                                                <figure>
                                                    {" "}
                                                    <img
                                                        src="/assets/images/tour_guide_01.jpg"
                                                        alt="One"
                                                    />
                                                </figure>
                                            </div>
                                            <div class="thumb_slide">
                                                <figure>
                                                    {" "}
                                                    <img
                                                        src="/assets/images/tour_guide_03.jpg"
                                                        alt="One"
                                                    />
                                                </figure>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </Layout>
        </>
    )
}
export default ServiceDetailAllergy